import { Container, Row, Col } from 'react-bootstrap';

import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import loaderImg from './preloader2.jpg';


const Loader = () => {


    return (
        <>
            
        <Container className='d-flex justify-content-center'>
        <Row>
        <Col><h5 style={{color:'floralwhite'}}>Please wait .. products will be here shortly</h5></Col>
        </Row>
        </Container>
            <Container className='mb-3'>
                <Row>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={loaderImg} />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                {/* <Placeholder.Button variant="primary" xs={6} /> */}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={loaderImg} />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                {/* <Placeholder.Button variant="primary" xs={6} /> */}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={loaderImg} />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                {/* <Placeholder.Button variant="primary" xs={6} /> */}
                            </Card.Body>
                        </Card>
                    </Col> <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={loaderImg} />
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                                {/* <Placeholder.Button variant="primary" xs={6} /> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row >
            </Container>
        </>
    )
}

export default Loader;