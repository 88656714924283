import Container from 'react-bootstrap/Container';
import {Row, Col, Form, Button} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import second from "./second.png";
import third from "./third.png";
import fourth from "./fourth.png";
import fifth from "./fifth.png";
import sixth from "./sixth.png";
import seventh from "./seventh.png";

// need to add images
import "./Home.css"

const Home=()=>{
    let navigate = useNavigate();
    const onAddNewItem = (e) => {
        e.preventDefault();
        navigate('/addmenuitem');
    }
    const [passKey, setPassKey] = useState("");
    const [hide, setHide] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const retreiveKey = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/menu/cmpKey`, {
            key: passKey
        })
            .then(results => {
                if(results.data.output===true)
                    setHide(false);
                else
                    setHide(true);
            setIsLoading(false);
            }
            )
            .catch(e => {
                setHide(true);
                setIsLoading(false);
                // setSearchItem("");
            })

    }
    return(
        <div className="home-container">
            
            <Container>
            <br></br>
            <Row calss= "mt-1">
            
                <h3>
                    <marquee behavior="scroll" direction="right" scrollamount="11" >
                       <b> <p style={{color:'white'}}>WELCOME TO PRICE CENTER</p></b></marquee>
                </h3>
                {/* <Row>
                    <Col></Col>
                    <Col> <div class="scrolling-text">
                <h3>
                    <b><p style={{color: 'white'}}>WELCOME TO PRICE CENTER</p></b>
                </h3>
                </div></Col>
                    <Col></Col>
                </Row> */}
              
            </Row>
            <Container>
            <Row className= "mt=2">
                <Carousel style={{ width: '100%'}}>
                    <Carousel.Item interval={3000}>
                
                <img 
                    className="d-block w-100 slideimages"
                    
                    src={"https://dailyhighclub.com/cdn/shop/products/Designer-Floral-Diamond-Water-Pipe_A-1.png?v=1695398202"}
                    // https://t4.ftcdn.net/jpg/05/10/77/67/360_F_510776735_jswrsH9wBuLU1lEi3bsVJG3gOhvtVoBF.jpg
                    // https://cdn.shopify.com/s/files/1/0043/9507/3582/products/Skull_Glow_in_the_dark_Beaker_Bongs.jpg?v=1638606094
                    alt="Water pipes"
                    />
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img
                    className="d-block slideimages"

                    src={second}

                    alt="Hand pipes"
                    />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                    <img 
                    className="d-block w-100 slideimages" 
                    src = {third}        

                    alt="Bowls&downstems"
                    />
                    </Carousel.Item>

                <Carousel.Item interval={3000}>
                    <img 
                    className="d-block w-100 slideimages" 
                    src = {fourth}        

                    alt="Bubblers"
                    />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                    <img 
                    className="d-block w-100 slideimages"  
                    src={fifth}

                    alt="Chillums"
                    />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                    <img 
                    className="d-block w-100 slideimages"  
                    src={sixth}

                    alt="Smoking Mugs"
                    />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                    <img 
                    className="d-block w-100 slideimages"  
                    src={seventh}

                    alt="Smoking Mugs"
                    />
                    </Carousel.Item>
            </Carousel>
            </Row>
            </Container>
            <br></br>
            <Row className='menu-center-text mt-3'>
                    <Form className="mb-3" onSubmit={(e) => { retreiveKey(e) }} >
                        <Row>
                        <Col className='col-md-2'>
                        <Form.Control
                            value={passKey}
                            type='password'
                            onChange={(e) => { setPassKey(e.target.value) }}
                            id="key-name"
                            placeholder="KEY"
                        />
                        </Col>
                        
                        <Col className='col-md-1'>
                        <Button type='submit'  disabled={isLoading}>{isLoading ? 'Loading...' : 'SUBMIT'}</Button>
                        </Col>
                        </Row>
                    </Form>
            </Row>
            {
                hide===false?
            <Row>
                    <Col>
                        <Button variant="primary" onClick={onAddNewItem}   >
                            Add new Item
                        </Button>
                    </Col>
                </Row>:null
            }
            </Container>
         
        </div>
        
        )
}

export default Home;