import './App.css';
import Navigationbar from './navbar/Navbar';
import {BrowserRouter} from 'react-router-dom';
import React, { useEffect } from 'react';
import backgroundImage from './wallp2.png';
import video1 from './whitesmoke.mov';
function App() {
  return (
    <BrowserRouter>
    <div className='App'>
    <video className="background-video" src={video1} autoPlay loop={true} muted={true}></video>
           
    <Navigationbar />
   
   </div>
   </BrowserRouter>
  );
}

export default App;
